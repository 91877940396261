.panel-body {
    padding: 15px;
    background-color: #300a24;
    color: #fff;
    height: 350px;

  }
  .panel-heading {
    text-align: left;
  }
  
  .footer {
    padding: 30px 0 30px 0;
    background-color: #f8f8f8;
    border-top: solid 1px #e7e7e7;
  }
  .marginb {
    margin-bottom: 45px;
  }
  
  .footer.text-center span {
    color: #300a24;
  }
  
  .fav {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 22px;
    color: #525252;
    text-decoration: none;
  }
  
  body {
    font-family: ubuntu;
    
  }
  
  .fakeButtons {

    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #000;
    position: relative;
    top: 6px;
    left: 6px;
    background-color: #e35f32;
    border-color: #e35f32;
    display: inline-block;
  }
  
  .fakeMinimize {
    left: 11px;
    background-color: green;
    border-color: #72716a;
  }
  
  .fakeZoom {
    left: 16px;
    background-color: #72716a;
    border-color: #72716a;
  }
  
  .fakeMenu {
    width: 350px;
    box-sizing: border-box;
    height: 25px;
    background-color: #bbb;
    margin: 0 auto;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  
  .fakeScreen {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  #set-paracode{
    position: relative;
    text-align: left;
    font-size: 1.25em;
    font-family: ubuntu;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
  }
  
  span {
    color: #fff;
    font-weight: bold;
  }
  
  .line1 {
    color: #fafafafa;
    -webkit-animation: type 0.5s 1s steps(20, end) forwards;
    animation: type 0.5s 1s steps(20, end) forwards;
  }
  
  .cursor1 {
    -webkit-animation: blink 1s 2s 2 forwards;
    animation: blink 1s 2s 2 forwards;
  }
  
  .line2 {
    color: #fafafafa;
    -webkit-animation: type 0.5s 4.25s steps(20, end) forwards;
    animation: type 0.5s 4.25s steps(20, end) forwards;
  }
  
  .cursor2 {
    -webkit-animation: blink 1s 5.25s 2 forwards;
    animation: blink 1s 5.25s 2 forwards;
  }
  
  .line3 {
    color: #fafafafa;
    -webkit-animation: type 0.5s 7.5s steps(20, end) forwards;
    animation: type 0.5s 7.5s steps(20, end) forwards;
  }
  
  .cursor3 {
    -webkit-animation: blink 1s 8.5s 2 forwards;
    animation: blink 1s 8.5s 2 forwards;
  }
  
  .line4 {
    color: #fff;
    -webkit-animation: type 0.5s 10.75s steps(20, end) forwards;
    animation: type 0.5s 10.75s steps(20, end) forwards;
  }
  
  .cursor4 {
    -webkit-animation: blink 1s 11.5s infinite;
    animation: blink 1s 8.5s infinite;
  }
  
  @-webkit-keyframes blink {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes type {
    to {
      width: 17em;
    }
  }
  
  @keyframes type {
    to {
      width: 17em;
    }
  }
  